import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { AppEnvironment } from './types';

let appEnv: AppEnvironment = 'production';

if (window.location.hostname === 'localhost') {
	appEnv = 'development';
}

export const APP_ENV: AppEnvironment = appEnv;

export const projectId =
	(APP_ENV === 'production'
		? process.env.REACT_APP_PRODUCTION_APP_ID
		: process.env.REACT_APP_DEVELOPMENT_APP_ID) ?? '';

// export const projectId = process.env.REACT_APP_PRODUCTION_APP_ID ?? '';

console.log('projectId', projectId);

// const firebaseConfig: FirebaseOptions = {
// 	apiKey: 'AIzaSyDambZVBF1GKNsBFjbdVuvfE0UgBOcwvZI',
// 	authDomain: `${projectId}.firebaseapp.com`,
// 	databaseURL: `https://${projectId}.firebaseio.com`,
// 	projectId: projectId,
// 	storageBucket: `${projectId}.appspot.com`,
// 	messagingSenderId: '420251317876',
// 	appId: '1:420251317876:web:fc1d2908dd453e9878c321',
// 	measurementId: 'G-H2QCPERQ7H',
// };

const firebaseConfig: FirebaseOptions = {
	apiKey: 'AIzaSyDambZVBF1GKNsBFjbdVuvfE0UgBOcwvZI',
	authDomain: 'pierexchange-3d152.firebaseapp.com',
	databaseURL: 'https://pierexchange-3d152-default-rtdb.firebaseio.com',
	projectId: 'pierexchange-3d152',
	storageBucket: 'pierexchange-3d152.appspot.com',
	messagingSenderId: '782541236407',
	appId: '1:782541236407:web:dc2c2cea23661ae3f58532',
	measurementId: 'G-H2QCPERQ7H',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

// if (APP_ENV === 'development') {
// 	connectAuthEmulator(auth, 'http://localhost:9098');
// 	connectFunctionsEmulator(functions, 'localhost', 5001);
// 	connectFirestoreEmulator(db, 'localhost', 8080);
// 	connectDatabaseEmulator(database, 'localhost', 9001);
// 	connectStorageEmulator(storage, 'localhost', 9199);
// }
