import React from 'react';
import { Box } from '@mui/material';
import LogoUrl from '../assets/images/icon-gray.png';

export default function LoadingScreen() {
	return (
		<Box display="flex" width="100%" height="100vh" alignItems="center" justifyContent="center">
			<img src={LogoUrl} alt="Aptivos" style={{ width: 75, display: 'block' }} />
		</Box>
	);
}
