import React from 'react';

type ActionProps = {
	type: string;
	payload?: any;
};

const types = {
	SET_STATE: 'notification/SET_STATE',
	SHOW: 'notification/SHOW',
	SHOW_ERROR: 'notification/SHOW_ERROR',
	SHOW_SUCCESS: 'notification/SHOW_SUCCESS',
	CLEAR: 'notification/CLEAR',
};

type StateProps = {
	visible: boolean;
	type: string;
	message: string;
};

const initialState: StateProps = {
	visible: false,
	type: 'info',
	message: '',
};

export const NotificationContext = React.createContext<any>(initialState);

export function notificationReducer(state: StateProps, action: ActionProps) {
	switch (action.type) {
		case types.SET_STATE:
			return {
				...state,
				...action.payload,
			};

		case types.SHOW:
			return {
				visible: true,
				...action.payload,
			};

		case types.SHOW_ERROR:
			return {
				visible: true,
				type: 'error',
				...action.payload,
			};

		case types.SHOW_SUCCESS:
			return {
				visible: true,
				type: 'success',
				...action.payload,
			};

		case types.CLEAR:
			return initialState;

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
}
