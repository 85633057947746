import React from 'react';

import { FiltersContext, filtersReducer, filterTypes } from '../reducer/filters';
import { GlobalFilterType, VendorSearchOption } from '../config/types';

interface Props {
	children: React.ReactNode;
}

export default function FiltersProvider({ children }: Props) {
	const { contextFiltersState: initialState } = React.useContext(FiltersContext);
	const [state, dispatch] = React.useReducer(filtersReducer, initialState);

	const setContextFilter = (
		filter: keyof GlobalFilterType,
		value: string | VendorSearchOption | null,
	) => {
		if (
			(filter === 'company' && typeof value !== 'string') ||
			(filter !== 'company' && typeof value === 'string')
		) {
			dispatch({
				type: filterTypes.setFilter,
				payload: {
					[filter]: value,
				},
			});
		}
	};

	const clearContextFilter = (filter: keyof GlobalFilterType) => {
		dispatch({
			type: filterTypes.clearFilter,
			payload: {
				filter,
			},
		});
	};

	const clearAllContextFilters = () => {
		dispatch({
			type: filterTypes.clearAllFilters,
		});
	};

	return (
		<FiltersContext.Provider
			value={{
				contextFiltersState: state,
				setContextFilter,
				clearContextFilter,
				clearAllContextFilters,
			}}
		>
			{children}
		</FiltersContext.Provider>
	);
}
