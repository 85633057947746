import React from 'react';
import {
	BreadcrumbsContext,
	breadcrumbsReducer,
	StateProps,
	ActionProps,
} from '../reducer/breadcrumbs';

export default function BreadcrumbsProvider({ children }: any) {
	const initialState = React.useContext(BreadcrumbsContext);
	const [state, dispatch] = React.useReducer((state: StateProps, action: ActionProps) => {
		// console.log('action: ', action);
		// console.trace('Calling breadcrumbsReducer'); // Add this line
		return breadcrumbsReducer(state, action);
	}, initialState);

	return (
		<BreadcrumbsContext.Provider value={{ state, dispatch }}>
			{children}
		</BreadcrumbsContext.Provider>
	);
}
