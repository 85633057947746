import React from 'react';
import { GlobalFilterType, VendorSearchOption } from '../config/types';

const initialFilters: GlobalFilterType = {
	searchText: '',
	company: null,
	category: 'all',
	tag: 'all',
	companyType: 'all',
	postType: 'all',
	postSubtype: 'all',
	service: 'all',
};

export enum filterTypes {
	setFilter = 'SET_FILTER',
	clearFilter = 'CLEAR_FILTER',
	clearAllFilters = 'CLEAR_ALL_FILTERS',
}

type ActionMap<M extends { [index: string]: any }> = {
	[Key in keyof M]: M[Key] extends undefined
		? {
				type: Key;
		  }
		: {
				type: Key;
				payload: M[Key];
		  };
};

type FilterPayload = {
	[filterTypes.setFilter]: {
		[Key in keyof Partial<GlobalFilterType>]: GlobalFilterType[Key];
	};
	[filterTypes.clearFilter]: {
		filter: keyof GlobalFilterType;
	};
	[filterTypes.clearAllFilters]: undefined;
};

export type FilterActions = ActionMap<FilterPayload>[keyof ActionMap<FilterPayload>];

export const FiltersContext = React.createContext<{
	contextFiltersState: GlobalFilterType;
	setContextFilter: (
		filter: keyof GlobalFilterType,
		value: string | VendorSearchOption | null,
	) => void;
	clearContextFilter: (filter: keyof GlobalFilterType) => void;
	clearAllContextFilters: () => void;
}>({
	contextFiltersState: initialFilters,
	setContextFilter: () => null,
	clearContextFilter: () => null,
	clearAllContextFilters: () => null,
});

export function filtersReducer(state: GlobalFilterType, action: FilterActions) {
	switch (action.type) {
		case filterTypes.setFilter:
			return {
				...state,
				...action.payload,
			};

		case filterTypes.clearFilter:
			return {
				...state,
				[action.payload.filter]: initialFilters[action.payload.filter],
			};

		case filterTypes.clearAllFilters:
			return {
				...initialFilters,
			};

		default:
			return {
				...state,
			};
	}
}
