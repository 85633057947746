import React, { FC } from 'react';

type SettingsContext = {
	themeMode: 'light' | 'dark' | null;
	setThemeMode: (newTheme: 'light' | 'dark') => void;
};
// eslint-disable-next-line
const maybeInitialTheme = 'light'; // window.localStorage.getItem('_app_theme') ?? '';

const initialState: SettingsContext = {
	themeMode: 'light', //['dark', 'light'].includes(maybeInitialTheme)
	// ? (maybeInitialTheme as 'light' | 'dark')
	// : null,
	setThemeMode: () => null,
};

export const SettingsContext = React.createContext(initialState);

interface Props {
	children: React.ReactNode;
}

const SettingsProvider: FC<Props> = ({ children }) => {
	const [mode, setMode] = React.useState(initialState.themeMode);
	// eslint-disable-next-line
	const setThemeMode = (newTheme: 'light' | 'dark') => {
		setMode('light');
	};

	return (
		<SettingsContext.Provider value={{ themeMode: mode, setThemeMode }}>
			{children}
		</SettingsContext.Provider>
	);
};

export default SettingsProvider;
