import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, database, db } from '../../config/firebase';
import { User } from 'firebase/auth';
import React from 'react';
import {
	MemberCompanyDB,
	MemberCompanyModel,
	UserModel1,
	VendorCompanyDB,
	VendorCompanyModel,
} from '../../config/types';
import { NotificationContext } from '../../reducer/notification';
import {
	collection,
	collectionGroup,
	doc,
	getDoc,
	onSnapshot,
	query,
	Unsubscribe,
	updateDoc,
	where,
} from 'firebase/firestore';
import { onDisconnect, onValue, ref, serverTimestamp, set } from 'firebase/database';
import { userConverter } from '../firestoreDataConverters';

const triggerPresence = (uid: string) => {
	const userStatusDatabaseRef = ref(database, '/status/' + uid);

	const isOfflineForDatabase = {
		online: false,
		lastChanged: serverTimestamp(),
	};

	const isOnlineForDatabase = {
		online: true,
		lastChanged: serverTimestamp(),
	};

	onValue(ref(database, '.info/connected'), async (snapshot) => {
		if (snapshot.val() === false) {
			return;
		}

		await set(userStatusDatabaseRef, isOnlineForDatabase);

		await onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase);
	});
};

export type UserProfile = {
	user: User | null | undefined;
	userData: UserModel1 | null;
	companyData: Partial<VendorCompanyDB> | Partial<MemberCompanyDB> | null;
	pricingPlan: any;
	freeTrial: any;
	loadingAuthState: boolean;
};

// Custom hook to read  auth record and user profile doc
export function useUserData(): UserProfile {
	const [user, loadingAuthState] = useAuthState(auth);
	const [userData, setUserData] = React.useState<UserModel1 | null>(null);
	const [companyData, setCompanyData] = React.useState<
		Partial<VendorCompanyDB> | Partial<MemberCompanyDB> | null
	>(null);
	const [pricingPlan, setPricingPlan] = React.useState<any>(null);
	const [freeTrial, setFreeTrial] = React.useState<any>(null);

	const [loading, setLoading] = React.useState(true);

	const { dispatch: dispatchNotification } = React.useContext(NotificationContext);

	React.useEffect(() => {
		if (!loadingAuthState && !user) {
			setLoading(false);
		}
	}, [loadingAuthState]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (!user) {
			setUserData(null);
			return undefined;
		}

		triggerPresence(user.uid);

		const ref = doc(collection(db, 'users'), user.uid).withConverter(userConverter);
		const unsubscribe = onSnapshot(
			ref,
			async (doc) => {
				if (doc.exists()) {
					const data = doc.data();

					if (data.emailVerified !== user.emailVerified) {
						await updateUserEmailVerifiedStatus(user.uid, user.emailVerified);
					}

					if (data.role !== 'admin' && !data.avatar) {
						data.avatar = user.photoURL ?? '';
					}

					setUserData({ ...data, uid: user.uid, email: user.email ?? '' });
					setLoading(false);
				}
			},
			(error) => {
				console.error(error);
				dispatchNotification({
					type: 'notification/SHOW_ERROR',
					payload: {
						message: error?.message ?? 'Unknown error',
					},
				});
				setLoading(false);
			},
		);

		return () => {
			unsubscribe();
		};
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (!userData || userData.role === 'admin' || !userData.companyId) {
			setCompanyData(null);
			return undefined;
		}

		const queryCollection = userData.role === 'vendor' ? 'vendors' : 'members';

		const ref = doc(collection(db, queryCollection), userData.companyId);

		const unsubscribe = onSnapshot(
			ref,
			(doc) => {
				const data: any = doc.data();

				const companyData: VendorCompanyModel | MemberCompanyModel = {
					id: doc.id,
					...data,
				};
				setCompanyData(companyData);
			},
			(error) => {
				console.error(error);
				dispatchNotification({
					type: 'notification/SHOW_ERROR',
					payload: {
						message: error?.message ?? 'Unknown error',
					},
				});
			},
		);

		return () => {
			unsubscribe();
		};
	}, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (!userData || userData.role !== 'vendor' || !userData.companyId) {
			setFreeTrial(null);
			return undefined;
		}

		(async () => {
			try {
				const trialDoc = await getDoc(doc(db, `trials/${userData.companyId}`));

				if (!trialDoc.exists()) {
					return undefined;
				}

				const { ends }: any = trialDoc.data();

				setFreeTrial(ends);
			} catch (error: any) {
				console.error(error);
				dispatchNotification({
					type: 'notification/SHOW_ERROR',
					payload: {
						message: error?.message ?? 'Unknown error',
					},
				});
			}
		})();
	}, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (!userData || userData.role !== 'vendor' || !userData.companyId) {
			setPricingPlan(null);
			return undefined;
		}

		let unsubscribe: Unsubscribe;

		const getPricingPlan = async (companyId: string) => {
			const ref = query(
				collectionGroup(db, 'subscriptions'),
				where('metadata.main_set', '==', 'true'),
				where('metadata.company_id', '==', companyId),
				where('canceled_at', '==', null),
				where('status', 'in', ['trialing', 'active']),
			);

			unsubscribe = onSnapshot(
				ref,
				(query) => {
					if (!query.empty) {
						setPricingPlan({ ...query.docs[0].data() });
					} else {
						setPricingPlan(false);
					}
				},
				(error) => {
					console.error(error);
					dispatchNotification({
						type: 'notification/SHOW_ERROR',
						payload: {
							message: error?.message ?? 'Unknown error',
						},
					});
				},
			);
		};

		getPricingPlan(userData?.companyId);

		return () => {
			unsubscribe();
		};
	}, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

	const updateUserEmailVerifiedStatus = async (userId: string, newStatus: boolean) => {
		try {
			await updateDoc(doc(db, `users/${userId}`), {
				emailVerified: newStatus,
			});
		} catch (error: any) {
			console.error(error);
		}
	};
	return {
		user,
		userData,
		companyData,
		pricingPlan,
		freeTrial,
		loadingAuthState: loading,
	};
}
