import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
// eslint-disable-next-line
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
import Router from './config/router';
import UserProvider from './provider/user';
import NotificationProvider from './provider/notification';
import TermsProvider from './provider/terms';
import BreadcrumbsProvider from './provider/breadcrumbs';
import { createTheme, CssBaseline, Theme } from '@mui/material';
import FiltersProvider from './provider/filters';
import apolloClient from './graphql/client';
import { SettingsContext } from './provider/settings';
// import { auth } from './config/firebase';
// import { signInAnonymously } from 'firebase/auth';
declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

function App() {
	// signInAnonymously(auth);
	// eslint-disable-next-line
	const prefersDarkMode = false; //useMediaQuery('(prefers-color-scheme: dark)');

	const { themeMode } = React.useContext(SettingsContext);
	// eslint-disable-next-line
	const getThemeString = (b: boolean) => (b ? 'dark' : 'light');

	const theme = React.useMemo(
		() => {
			const mode = 'light'; // themeMode !== null ? themeMode : getThemeString(prefersDarkMode);

			return createTheme({
				breakpoints: {
					values: {
						xs: 0,
						sm: 600,
						md: 960,
						lg: 1400,
						xl: 1920,
					},
				},
				typography: {
					fontFamily: [
						'-apple-system',
						'BlinkMacSystemFont',
						'"Segoe UI"',
						'Arial',
						'sans-serif',
						'"Apple Color Emoji"',
						'"Segoe UI Emoji"',
						'"Segoe UI Symbol"',
					].join(','),
					h1: {
						fontWeight: 700,
						lineHeight: 1.1,
						fontSize: 36,
					},
					h2: {
						fontWeight: 700,
						lineHeight: 1.2,
						fontSize: 28,
					},
					h3: {
						fontWeight: 700,
						lineHeight: 1.3,
						fontSize: 24,
					},
					h4: {
						fontWeight: 700,
						fontSize: 20,
						lineHeight: 1.3,
					},
					h5: {
						fontWeight: 700,
						fontSize: 18,
						lineHeight: 1.3,
					},
					h6: {
						fontWeight: 700,
						fontSize: 16,
						lineHeight: 1.4,
					},
					button: {
						textTransform: 'none',
					},
				},
				palette: {
					mode,
					primary: {
						main: 'light' === mode ? '#076492' : '#0ba6f4',
					},
					secondary: {
						main: 'light' === mode ? '#129ab5' : '#45d1ed',
					},
					background: {
						default: 'light' === mode ? '#fafafa' : '#303030',
						paper: 'light' === mode ? '#fff' : '#f0ead6', //424242
					},
					text: {
						primary: 'light' === mode ? '#000000' : '#000000',
						secondary: 'light' === mode ? '#757575' : '#BBBBBB',
					},
				},
				shape: {
					borderRadius: 6,
				},
				components: {
					MuiLink: {
						defaultProps: {
							underline: 'hover',
						},
					},
					MuiTextField: {
						defaultProps: {
							variant: 'standard',
						},
					},
					MuiFormControl: {
						defaultProps: {
							variant: 'standard',
						},
					},
					MuiSelect: {
						defaultProps: {
							variant: 'standard',
						},
					},
				},
			});
		},
		[themeMode], // eslint-disable-line react-hooks/exhaustive-deps
	);

	return (
		<HelmetProvider>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{process.env.REACT_APP_TITLE}</title>
				<link rel="canonical" href={process.env.REACT_APP_URL} />
			</Helmet>
			<ApolloProvider client={apolloClient}>
				<ThemeProvider theme={theme}>
					<NotificationProvider>
						<FiltersProvider>
							<UserProvider>
								<CssBaseline />
								<BreadcrumbsProvider>
									<TermsProvider>
										<Router />
									</TermsProvider>
								</BreadcrumbsProvider>
							</UserProvider>
						</FiltersProvider>
					</NotificationProvider>
				</ThemeProvider>
			</ApolloProvider>
		</HelmetProvider>
	);
}

export default App;
