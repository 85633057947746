import React from 'react';
// import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../config/firebase';
import { TermDisplay } from '../config/types';
import { collection, onSnapshot } from 'firebase/firestore';

export type StateProps = {
	loading: boolean;
	loadingTerms: boolean;
	terms: {
		[taxonomy: string]: {
			[id: string]: TermDisplay;
		};
	};
};

const initialState: StateProps = {
	loading: true,
	loadingTerms: true,
	terms: {},
};

export const TermsContext = React.createContext<StateProps>(initialState);

export default function TermsProvider({ children }: any) {
	const collectionRef = collection(db, 'terms');
	const [state, setState] = React.useState<StateProps>(initialState);
	React.useEffect(() => {
		const unsubscribe = onSnapshot(
			collectionRef,
			(snapshot) => {
				const newTerms: { [taxonomy: string]: { [id: string]: TermDisplay } } = {};

				if (!snapshot.empty) {
					snapshot.forEach((doc) => {
						const data = doc.data();
						if (undefined === newTerms[data.taxonomy]) {
							newTerms[data.taxonomy] = {};
						}
						const newTerm: TermDisplay = {
							id: doc.id,
							name: data.name,
							slug: data.slug,
							allPostsNumber: data.allPostsNumber,
							productNumber: data.productNumber,
							postNumber: data.postNumber,
							serviceNumber: data.serviceNumber,
							questionNumber: data.questionNumber,
							taxonomy: data.taxonomy,
						};

						newTerms[data.taxonomy][doc.id] = newTerm;
					});
				}

				setState((prevState) => ({
					...prevState,
					terms: newTerms,
					loadingTerms: false,
					loading: false,
				}));
			},
			(error) => {
				console.error(error);
			},
		);
		return unsubscribe;
	}, []);

	return <TermsContext.Provider value={state}>{children}</TermsContext.Provider>;
}
